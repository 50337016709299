import {
  parseQueryString,
  loadMultiFile,
  isArray,
  mergeQueryString
} from '@shein/common-function'
import { NECESSARY_THIRD_SDK } from './config'
import { PPGA_CHECK_TYPE, AFTERPAY_CHECK_TYPE, SIGNUP_CHECK_TYPE } from 'public/src/pages/common/constants'
// PayPal-Venmo和PayPal-GApaypal不走Inline
const FIXED_INLINE_PAY_METHODS = ['PayPal-Venmo', 'PayPal-GApaypal']

class Methods {
  checkoutInfo = {}

  clientInstanceCache = new Map()

  constructor(checkoutInfo) {
    this.checkoutInfo = checkoutInfo || {}
  }

  updateInfo(checkoutInfo) {
    Object.assign(this.checkoutInfo, checkoutInfo || {})
  }

  /**
   * 获取ppga账号信息
   *
   * @readonly
   * @memberof Methods
   */
  get ppgvAccountInfo() {
    const paymentMethods = isArray(this.checkoutInfo?.paymentMethods)
      ? this.checkoutInfo?.paymentMethods
      : []
    const ppgaMethod = paymentMethods.find(
      item => item.code === 'PayPal-GApaypal'
    )
    return ppgaMethod?.payment_sign_up || {}
  }

  static getPpgvAccountInfo({ paymentMethods = [] }) {
    const methods = isArray(paymentMethods) ? paymentMethods : []
    const ppgaMethod = methods.find(item => item.code === 'PayPal-GApaypal')
    return ppgaMethod?.payment_sign_up
  }

  static getAfterpayAccountInfo({ paymentMethods = [] }) {
    const methods = isArray(paymentMethods) ? paymentMethods : []
    const payMethod = methods.find(item => item.code === 'afterpay-card')
    return payMethod?.payment_sign_up
  }

  static getSignupAccountInfo({ paymentMethods = [] }) {
    const methods = isArray(paymentMethods) ? paymentMethods : []
    const signupAccountInfo = {}
    methods.forEach(item => {
      signupAccountInfo[item.code] = item?.payment_sign_up || {}
    })
    return signupAccountInfo
  }

  static getPayInlineAbtInfo({ abtInfo = {} }) {
    const { PayInline } = abtInfo || {}
    let payInlineInfo = {}
    if (PayInline?.p) {
      let channel = PayInline.p.split('&')
      channel.forEach(item => {
        let inlineInfoItem = item.split('=')
        if (inlineInfoItem[1]) {
          payInlineInfo[inlineInfoItem[0]] = inlineInfoItem[1].split(',')
        }
      })
    }
    if (!payInlineInfo['paypal']) {
      payInlineInfo['paypal'] = FIXED_INLINE_PAY_METHODS
    } else {
      payInlineInfo['paypal'] = [
        ...new Set([...payInlineInfo['paypal'], ...FIXED_INLINE_PAY_METHODS])
      ]
    }
    return payInlineInfo
  }

  static getRpcPayment({ paymentMethods = [] }) {
    const methods = isArray(paymentMethods) ? paymentMethods : []
    return methods.find(item => item.code === 'routepay-card') || null
  }

  static getRpcCardToken({ paymentMethods = [], rpcPayment }) {
    const rpcMethod = rpcPayment || Methods.getRpcPayment({ paymentMethods })
    return rpcMethod?.card_token
  }

  static getDefaultPayment({ paymentMethods = [] }) {
    const methods = isArray(paymentMethods) ? paymentMethods : []
    const enabledMethods = methods.filter(item => item.enabled == 1)
    // 如果存在可用的默认支付方式，优先使用默认支付方式，否则使用第一个可用的支付方式
    if (enabledMethods.length)
      return (
        enabledMethods.find(item => item.default_payment) || enabledMethods[0]
      )
    // 如果都不可用，则优先取默认支付方式，否则使用第一个不可用的支付方式
    else return methods.find(item => item.default_payment) || methods[0]
  }

  static getNeedPayInlineMethods({
    payInlineAbtInfo,
    paymentMethods = [],
    abtInfo = {}
  }) {
    payInlineAbtInfo =
      payInlineAbtInfo ?? Methods.getPayInlineAbtInfo({ abtInfo })
    const payInlineMethods = Object.values(payInlineAbtInfo || {}).reduce(
      (prev, next) => [...prev, ...next],
      []
    )
    return (
      paymentMethods
        ?.filter(item => payInlineMethods.indexOf(item.code) > -1)
        .map(v => v.code) || []
    )
  }

  // 初始化pp vaulting
  static initPaypalVaultingConfig({ abtInfo = {}, ppgvAccountInfo = {} }) {
    const signAbtParams = parseQueryString(abtInfo?.PayPalVaultingnew?.p || '')
    const paypalGaVault = {
      valid: false,
      visibleArrow: false,
      radioType: PPGA_CHECK_TYPE.CHECKED,
      type: PPGA_CHECK_TYPE.CHECKED,
      express: false // 是否使用快捷选中方式 - 未签约，且允许选择是否记住卡号
    }
    // 无论签约与否都开启
    if (
      signAbtParams?.signed === 'open' &&
      signAbtParams?.unsigned === 'open'
    ) {
      paypalGaVault.valid = paypalGaVault.visibleArrow = true
    }
    // 签约才开启
    if (
      signAbtParams?.signed === 'open' &&
      signAbtParams?.unsigned !== 'open'
    ) {
      paypalGaVault.visibleArrow = paypalGaVault.valid =
        !!ppgvAccountInfo?.sign_up_email
    }
    // 未签约才开启
    if (
      signAbtParams?.signed !== 'open' &&
      signAbtParams?.unsigned === 'open'
    ) {
      paypalGaVault.valid = paypalGaVault.visibleArrow =
        !ppgvAccountInfo?.sign_up_email
    }
    // 无论签约与否都不开启 默认
    if (
      signAbtParams?.signed !== 'open' &&
      signAbtParams?.unsigned !== 'open'
    ) {
      paypalGaVault.valid = paypalGaVault.visibleArrow = false
    }

    // wiki.pageId=1141988501
    paypalGaVault.express = false
    if (
      paypalGaVault.valid &&
      paypalGaVault.visibleArrow &&
      signAbtParams?.unsigned === 'open'
    ) {
      if (!ppgvAccountInfo?.sign_up_email) {
        paypalGaVault.express = true
        paypalGaVault.visibleArrow = false
      }
    }

    if (!paypalGaVault.valid) {
      paypalGaVault.radioType = PPGA_CHECK_TYPE.UNCHECKED
      paypalGaVault.type = PPGA_CHECK_TYPE.UNCHECKED
    }

    return paypalGaVault
  }

  // 初始化afterpay vaulting
  static initAfterpayVaultingConfig({ abtInfo = {}, afterPayAccountInfo = {}, paymentMethods = [] }) {
    const noCardTokenPayAbtInfo = abtInfo
    let afterPayVault = {
      valid: true,
      tipShow: false,
      radioType: AFTERPAY_CHECK_TYPE.CHECKED,	//用于展示哪个勾选
      type: AFTERPAY_CHECK_TYPE.CHECKED,			//实际应用的勾选
      visibleArrow: true, // 是否展示valuting选择弹窗
      switch: false, // 是否开启token支付
      title: 'A', // 支付方式标题
    }
    // ABT：开启token支付
    const paymentTokenSupport = noCardTokenPayAbtInfo?.PaymentUnsignExp?.p?.PaymentSignupShow === 'Show' || false
    // 是否默认勾选remember
    const paymentInfo = paymentMethods?.find?.(item => item.code === 'afterpay-card') || {}

    const afterpaytokenRemember = paymentInfo?.default_sign_remember == 1
    
    // ABT：文案的abt
    const afterpaySavedword = noCardTokenPayAbtInfo?.afterpaySavedword?.p?.afterpaySavedwordStatus === 'A'

    afterPayVault.switch = paymentTokenSupport ? true : false
    afterPayVault.title = afterpaySavedword ? 'A' : 'B'
    afterPayVault.valid = paymentTokenSupport ? true : false
    afterPayVault.radioType = afterpaytokenRemember ? AFTERPAY_CHECK_TYPE.CHECKED : AFTERPAY_CHECK_TYPE.UNCHECKED
    afterPayVault.type = afterpaytokenRemember ? AFTERPAY_CHECK_TYPE.CHECKED : AFTERPAY_CHECK_TYPE.UNCHECKED
    
    // 有签约信息
    if(afterPayAccountInfo?.id){
      // 不展示勾选开关
      afterPayVault.valid = false
      afterPayVault.radioType = AFTERPAY_CHECK_TYPE.CHECKED
      afterPayVault.type = AFTERPAY_CHECK_TYPE.CHECKED
    } else {
      // 无签约信息,展示老文案
      afterPayVault.title = ''
    }

    return afterPayVault
  }

  static initSignupPayVaultingConfig({ signupAccountInfo = {}, paymentMethods = [] }) {
    let signupPayVault = {}
    paymentMethods.forEach(item => {
      const { code, default_sign_remember, needToSign = false, supportOneTimePay = false } = item
      if (needToSign) {
        const signInfo = signupAccountInfo[code] || {}
        signupPayVault[code] = {
          valid: true,
          radioType: default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
          type: default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
          visibleArrow: supportOneTimePay,
        }

        if (signInfo?.id) {
          signupPayVault[code].valid = false
          signupPayVault[code].radioType = SIGNUP_CHECK_TYPE.CHECKED
          signupPayVault[code].type = SIGNUP_CHECK_TYPE.CHECKED
        }
      }
    })
    return signupPayVault
  }

  static async initPayPalSDK() {
    if (typeof window == 'undefined') return
    return loadMultiFile({ arr: NECESSARY_THIRD_SDK['PayPal-GApaypal'] })
  }

  static async getPayPalClientInstance({ clientToken }) {
    if (typeof window == 'undefined')
      return Promise.reject('window is undefined')
    try {
      const clientInstance = await window.braintree.client
        .create({
          authorization: clientToken
        })
        .catch(err => {
          throw err
        })
      return clientInstance
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getPaypalDeviceData(clientInstance) {
    if (!clientInstance) return Promise.reject('clientInstance is required')
    const dataCollectorInstance = await window.braintree.dataCollector
      .create({
        client: clientInstance
      })
      .catch(err => {
        // return { deviceData: '' }
        throw err
      })
    return dataCollectorInstance?.deviceData || ''
  }

  static checkTokenCvv({ card_type, cvv }) {
    // 允许输入000，V/M卡仅允许输入3位数CVV，AE卡允许输入3或4位数CVV，mastero允许不输入CVV
    // test
    // var arr = ['000', '123', '012', '210', '108', '0000', '0111', '1011', '1101', '1110', '0009', '6321']
    // for (let index = 0; index < arr.length; index++) {
    //    console.log(checkTokenCvv({
    //      cardType: 'MAESTRO',
    //      cvv: arr[index]
    //    }))
    //  }
    const cardType = (card_type || '')?.toUpperCase?.() || ''
    let reg = /^\d{3,4}$/
    const regs = {
      AMEX: /^\d{3,4}$/,
      MAESTRO: /^(\d{3}|\s{0})$/,
      other: /^\d{3}$/
    }
    if (cardType) {
      reg = regs[cardType] || regs.other
    }
    return reg.test(cvv)
  }

  static getCvvMaxLen({ card_type }) {
    const cardType = (card_type || '')?.toUpperCase?.() || ''
    const maxLens = {
      AMEX: 4,
      MAESTRO: 3,
      other: 3
    }
    if (cardType) return maxLens[cardType] || maxLens.other
    return 4
  }

  static getDeviceInfo() {
    const DEVICE_INFO = {
      colorDepth: window.screen.colorDepth,
      javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
      language: window.navigator.language,
      screenHeight: window.screen.height || 0,
      screenWidth: window.screen.width || 0,
      timeZoneOffset: new Date().getTimezoneOffset(),
      userAgent: window.navigator.userAgent,
      origin: window.location.origin
    }
    return DEVICE_INFO
  }

  static unFilterTokenData(data) {
    const { langPath, host } = gbCommonInfo
    const callbackUrl =
      host +
      langPath +
      '/ltspc/pay/result/unifiedCb?bill_no=' +
      data.billno +
      `&isOcp=1&ocpCbUrl=${encodeURIComponent(location.href)}`
    const failureUrl = callbackUrl
    const pendingUrl = callbackUrl
    const cancelUrl = mergeQueryString({
      url: callbackUrl,
      mergeObj: {
        ocpCancel: 1
      }
    })

    return {
      billno: data.billno || '',
      publicKeyId: data.publicKeyId || '',
      sessionId: data.sessionId || '',
      tokenId: data.tokenId || '',
      cardBin: data.cardBin || '',
      cardLastFour: data.cardLastFour || '',
      deviceFingerId: data.deviceFingerId || '',
      challengeWindowSize: data.challengeWindowSize || '',
      forterDeviceFingerprintID: data.forterDeviceFingerprintID || '',
      riskifiedDeviceFingerprintID: data.riskifiedDeviceFingerprintID || '',
      paymentCode: data.paymentCode || '',
      cvvHash: data.cvvHash || '',
      cvvCheck: data.cvvCheck || '',
      GB_cybs_loaded: window.GB_cybs_loaded ? 1 : 0,
      callbackUrl,
      cancelUrl,
      pendingUrl,
      failureUrl,
      device_language: navigator.language || navigator.browserLanguage || '',
      // 自定义参数，用于区分不同的token支付场景
      checkoutScene: 'ocp',
      ...Methods.getDeviceInfo()
    }
  }

  static getCache() {
    const { SiteUID } = gbCommonInfo || {}
    const CACHE_KEY = `${SiteUID}_OCP_CACHE`
    const cache = localStorage.getItem(CACHE_KEY)
    try {
      return cache ? JSON.parse(cache) : {}
    } catch (e) {
      return {}
    }
  }

  static setCache(data = {}) {
    const { SiteUID } = gbCommonInfo || {}
    const CACHE_KEY = `${SiteUID}_OCP_CACHE`
    localStorage.setItem(CACHE_KEY, JSON.stringify(data || {}))
  }

  static clearCache() {
    const { SiteUID } = gbCommonInfo || {}
    const CACHE_KEY = `${SiteUID}_OCP_CACHE`
    localStorage.removeItem(CACHE_KEY)
  }

  /**
   * 订单是否是属于未知状态
   *
   * @static
   * @param {*} res
   * @return {*}
   * @memberof Methods
   */
  static isPendingStatus(res) {
    const { action, result, pending } = res?.info || {}
    return action === 'direct' && result == 0 && pending == 1
  }
}

export default Methods
